<template>
  <b-card>
    <b-row>
      <b-col>
        <h6>
          {{ $t('fields.all') }}
          {{ `(${providerGames.length})` }}
        </h6>
      </b-col>
    </b-row>
    <!-- Table -->
    <b-table :fields="fields" :items="providerGames" responsive="sm" show-empty>
      <template #cell(#)="data">
        {{ data.index + 1 }}
      </template>
      <template #cell(status)="data">
        <b-badge v-if="data.value === 0" variant="success">
          {{ $t('utils.status.active') }}
        </b-badge>
        <b-badge v-else-if="data.value === 1" variant="warning">
          {{ $t('utils.status.maintainance') }}
        </b-badge>
        <b-badge v-else-if="data.value === 2" variant="danger">
          {{ $t('utils.status.inactive') }}
        </b-badge>
      </template>
      <template #cell(updatedAt)="data">
        {{ data.value | datetime }}
      </template>
      <template #cell(isAllowFreeCredit)="data">
        <b-badge :variant="data.value ? 'success' : 'danger'">
          {{ data.value ? 'ฟรีเครดิตเล่นได้' : 'ฟรีเครดิตเล่นไม่ได้' }}
        </b-badge>
      </template>
      <template #cell(id)="data">
        <div class="btn-group mb-2">
          <b-button
            v-if="$allowPermission('agent:manage.game')"
            :to="`/games/provider-games/${data.value}`"
            class="btn btn-info btn-sm"
            type="button"
          >
            {{ $t('buttons.edit') }}
          </b-button>
        </div>
      </template>
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
      <template #empty="">
        <p class="text-center text-muted">{{ $t('messages.nothing_here') }}</p>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'ProviderGameList',
  data() {
    return {
      fields: [
        '#',
        {
          key: 'name',
          label: this.$t('games.name'),
          thStyle: {
            minWidth: '200px',
          },
        },
        {
          key: 'status',
          label: this.$t('fields.status'),
          class: 'text-center',
        },
        {
          key: 'isAllowFreeCredit',
          label: 'Free Credits',
          class: 'text-center',
        },
        {
          key: 'updatedAt',
          label: this.$t('fields.updatedAt'),
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'id',
          label: '-',
          class: 'text-right',
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['providerGames']),
    isFetching() {
      return false
    },
  },
}
</script>
